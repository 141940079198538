import { isWithinInterval } from 'date-fns'

export default (app, redirect) => {
  if (
    !isWithinInterval(
      new Date(), // Now
      {
        start: new Date('2025-01-01 00:00:00 GMT+07:00'.replace(/-/g, '/')),
        end: new Date('2025-03-31 23:59:59 GMT+07:00'.replace(/-/g, '/'))
      }
    )
  ) {
    return redirect('/not-found')
  } else if (!app.$features('DWB-1649-EXTEND-MEMBER-GET-MEMBET-2025-F')) {
    return redirect('/not-found')
  }
}
